import { h, resolveComponent } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';
import Quasar from 'quasar/src/vue-plugin.js';;

import store from '@/store';
import i18n from '@/helpers/i18n';
import getLocalizedPath from '@/helpers/getLocalizedPath';
import dayjs from '@/helpers/dayjs';

import SignIn from '@/views/SignIn.vue';
import ResetPassword from '@/views/ResetPassword.vue';
import ResetSuccessfully from '@/views/ResetSuccessfully.vue';
import Contracts from '@/views/Contracts.vue';
import LegalAndPrivacy from '@/views/LegalAndPrivacy.vue';
import Dashboard from '@/views/Dashboard.vue';
import FoldersIndex from '@/views/FoldersIndex.vue';
import PatientsIndex from '@/views/PatientsIndex.vue';
import PendingIndex from '@/views/PendingIndex.vue';
import AppointmentsIndex from '@/views/AppointmentsIndex.vue';
import Glossary from '@/views/Glossary.vue';
import Account from '@/views/Account.vue';
import PatientsShow from '@/views/PatientsShow';
import ExamsShow from '@/views/ExamsShow';
import CompositionReport from '@/views/CompositionReport';
import FollowUpReport from '@/views/FollowUpReport';
import ExamsCompare from '@/views/ExamsCompare';
import CheckoutConfirmation from '@/views/CheckoutConfirmation';

const routes = [
  {
    path: 'sign-in',
    name: 'SignIn',
    component: SignIn,
    meta: {
      layoutName: 'SignIn',
    },
  },
  {
    path: 'reset-password',
    name: 'ResetPassword',
    component: ResetPassword,
    meta: {
      layoutName: 'SignIn',
    },
  },
  {
    path: 'reset-successfully',
    name: 'ResetSuccessfully',
    component: ResetSuccessfully,
    meta: {
      layoutName: 'SignIn',
    },
  },
  {
    path: 'Contracts',
    name: 'Contracts',
    component: Contracts,
    meta: {
      hideBreadcrumbs: true,
    },
  },
  {
    path: 'Legal&Privacy',
    name: 'Legal&Privacy',
    component: LegalAndPrivacy,
  },
  {
    path: '',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      hideBreadcrumbs: true,
    },
  },
  {
    path: 'folders',
    name: 'FoldersIndex',
    component: FoldersIndex,
  },
  {
    path: 'folders/:folderId/patients',
    name: 'PatientsIndex',
    component: PatientsIndex,
  },
  {
    path: 'folders/:folderId/patients/:patientId',
    name: 'PatientsShow',
    component: PatientsShow,
  },
  {
    path: 'folders/:folderId/patients/:patientId/reports/follow-up',
    name: 'FollowUpReport',
    component: FollowUpReport,
  },
  {
    path: 'folders/:folderId/patients/:patientId/reports/follow-up/print',
    name: 'FollowUpReportPrint',
    component: FollowUpReport,
    meta: {
      layoutName: 'Print',
    },
  },
  {
    path: 'folders/:folderId/patients/:patientId/exams/compare',
    name: 'ExamsCompare',
    component: ExamsCompare,
  },
  {
    path: 'folders/:folderId/patients/:patientId/exams/:examId',
    name: 'ExamsShow',
    component: ExamsShow,
  },
  {
    path: 'folders/:folderId/patients/:patientId/exams/:examId/reports/composition',
    name: 'CompositionReport',
    component: CompositionReport,
  },
  {
    path: 'folders/:folderId/patients/:patientId/exams/:examId/reports/composition/print',
    name: 'CompositionReportPrint',
    component: CompositionReport,
    meta: {
      layoutName: 'Print',
    },
  },
  {
    path: 'e/:token',
    name: 'CompositionReportPrintPublic',
    component: CompositionReport,
    meta: {
      layoutName: 'Print',
      isPublic: true,
    },
  },
  {
    path: 'appointments',
    name: 'AppointmentsIndex',
    component: AppointmentsIndex,
  },
  {
    path: 'pending',
    name: 'PendingIndex',
    component: PendingIndex,
  },
  {
    path: 'glossary',
    name: 'Glossary',
    component: Glossary,
  },
  {
    path: 'account',
    name: 'Account',
    component: Account,
  },
  {
    path: 'e/:token',
    name: 'Share Link',
    meta: {
      authException: true,
    },
  },
  {
    path: 'checkout-confirmation',
    name: 'CheckoutConfirmation',
    component: CheckoutConfirmation,
  },
];

const availableLocales = ['en', 'it', 'es', 'de'];
const defaultLocale = 'en';

const i18nRoutes = [
  ...routes.map((route) => {
    return {
      ...route,
      path: `/${route.path}`,
    };
  }),
  {
    path: '/:locale',
    component: {
      render: () => h(resolveComponent('RouterView')),
    },
    children: routes.map((route) => {
      return {
        ...route,
        name: `${route.name}Localized`,
      };
    }),
  },
];

const router = createRouter({
  base: process.env.VUE_APP_ROUTER_BASE_URL,
  history: createWebHistory(process.env.VUE_APP_ROUTER_BASE_URL),
  routes: i18nRoutes,
});

router.beforeEach((to, from, next) => {
  if (to.params.locale && !availableLocales.includes(to.params.locale)) {
    return next(getLocalizedPath({ name: 'Dashboard' }));
  }

  i18n.global.locale =
    store.getters['account/info']?.user.locale || defaultLocale;
  i18n.locale = store.getters['account/info']?.user.locale || defaultLocale;
  dayjs.locale(store.getters['account/info']?.user.locale || defaultLocale);

  let langName = 'en-GB';

  if (store.getters['account/info']?.user.locale === 'it') {
    langName = 'it';
  } else if (store.getters['account/info']?.user.locale === 'es') {
    langName = 'es';
  } else if (store.getters['account/info']?.user.locale === 'de') {
    langName = 'de';
  }

  if (langName !== Quasar.lang.isoName) {
    import('quasar/lang/' + langName).then((lang) =>
      Quasar.lang.set(lang.default)
    );
  }

  if (
    !to.meta?.layoutName &&
    !store.getters['account/info'] &&
    !to.meta.isPublic
  ) {
    return next(getLocalizedPath({ name: 'SignIn' }));
  }

  if (+to.params.folderId === -1) {
    return next(from);
  }

  next();
});

export default router;
