<template>
  <div v-if="isLoading" class="row justify-center">
    <q-spinner color="primary" size="3em" />
  </div>

  <div v-if="!isLoading">
    {{ message }}
  </div>
</template>

<script>
import { complete } from '@/api/purchases';

export default {
  data() {
    return {
      isLoading: false,
      message: null,
      stripe: null,
      elements: null,
    };
  },
  mounted() {
    if (window.Stripe) {
      this.retrievePaymentIntent();
    } else {
      this.loadStripe();
    }
  },
  methods: {
    loadStripe() {
      const stripeScript = document.createElement('script');

      stripeScript.async = true;
      stripeScript.src = 'https://js.stripe.com/v3/';
      stripeScript.onload = () => {
        this.retrievePaymentIntent();
      };

      document.head.appendChild(stripeScript);
    },
    async retrievePaymentIntent() {
      try {
        this.isLoading = true;

        this.stripe = window.Stripe(process.env.VUE_APP_STRIPE_ID);

        const { paymentIntent } = await this.stripe.retrievePaymentIntent(
          this.$route.query.payment_intent_client_secret
        );

        if (paymentIntent.status === 'succeeded') {
          this.completePurchase();
        } else if (paymentIntent.status === 'processing') {
          this.message =
            "Payment processing. We'll update you when payment is received.";

          window.setTimeout(() => {
            this.retrievePaymentIntent();
          }, 2 * 1000);
        } else if (paymentIntent.status === 'requires_payment_method') {
          this.message = 'Payment failed. Please try another payment method.';
        } else {
          this.message = 'Something went wrong.';
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    async completePurchase() {
      try {
        this.isLoading = true;

        await complete(this.$route.query.payment_intent);

        this.$router.push('/account?tab=billing');

        this.$q.notify({
          type: 'positive',
          message: this.$t('pages.checkoutConfirmation.success'),
          position: 'top-right',
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
