<template>
  <div class="row q-mb-md">
    <div class="col">
      <h6 class="q-ma-none">
        {{ $t('components.accountPreferencesTab.title') }}
      </h6>
      {{ $t('components.accountPreferencesTab.description') }}
    </div>
    <div class="col text-right">
      <template v-for="button of buttons" :key="button">
        <q-btn
          :color="button.color"
          :label="button.label"
          :outline="button.isOutline"
          :to="button.to"
          class="q-mb-md q-ml-sm"
          @click="button.onClick"
        />
      </template>
    </div>
  </div>

  <div class="row q-col-gutter-md">
    <div class="col-4 col-sm-2">
      <app-image-selector v-model="localAccount.logo" :readonly="!isEdit" />
    </div>
    <div class="column col-8 col-sm-10 q-col-gutter-md">
      <div class="row q-col-gutter-md">
        <q-select
          v-model="localAccount.user.measurement"
          :options="[
            {
              value: 'metric',
              label: $t('components.accountPreferencesTab.units.metric'),
            },
            {
              value: 'imperial',
              label: $t('components.accountPreferencesTab.units.imperial'),
            },
          ]"
          :label="
            $t('components.accountPreferencesTab.labels.measurementUnits')
          "
          :error="v$.localAccount.user.measurement.$error"
          :error-message="v$.localAccount.user.measurement.$errors[0]?.$message"
          :readonly="!isEdit"
          class="col-4"
          emit-value
          map-options
          hide-bottom-space
        />

        <q-select
          v-model="localAccount.user.locale"
          :options="[
            {
              value: 'en',
              label: $t('components.accountPreferencesTab.locales.en'),
            },
            {
              value: 'it',
              label: $t('components.accountPreferencesTab.locales.it'),
            },
            {
              value: 'es',
              label: $t('components.accountPreferencesTab.locales.es'),
            },
            {
              value: 'de',
              label: $t('components.accountPreferencesTab.locales.de'),
            },
          ]"
          :label="$t('components.accountPreferencesTab.labels.locale')"
          :error="v$.localAccount.user.locale.$error"
          :error-message="v$.localAccount.user.locale.$errors[0]?.$message"
          :readonly="!isEdit"
          class="col-4"
          emit-value
          map-options
          hide-bottom-space
        />

        <q-select
          v-model="localAccount.formula"
          :options="formulas"
          :readonly="!isEdit && true"
          :label="$t('components.accountPreferencesTab.labels.formula')"
          :error="v$.localAccount.formula.$error"
          :error-message="v$.localAccount.formula.$errors[0]?.$message"
          class="col-4"
          emit-value
          map-options
          hide-bottom-space
        />
      </div>

      <div class="col">
        <div class="col q-mb-md">
          <div v-if="!isEdit">
            <div class="q-mb-xs editor-label">
              {{ $t('components.accountPreferencesTab.labels.reportHeader') }}
            </div>

            <div class="editor-result" v-html="localAccount.reportHeader" />
          </div>

          <div v-else>
            <label for="header-editor" class="q-mb-sm editor-label">
              {{ $t('components.accountPreferencesTab.labels.reportHeader') }}
            </label>

            <q-editor
              :toolbar="[
                [
                  'bold',
                  'italic',
                  'underline',
                  'strike',
                  'undo',
                  'redo',
                  'Example',
                ],
                ['hr', 'link'],
              ]"
              id="header-editor"
              v-model.trim="localAccount.reportHeader"
              min-height="5rem"
            />
          </div>
        </div>

        <div class="col">
          <div v-if="!isEdit">
            <div class="q-mb-xs editor-label">
              {{ $t('components.accountPreferencesTab.labels.reportFooter') }}
            </div>

            <div class="editor-result" v-html="localAccount.reportFooter" />
          </div>

          <div v-else>
            <label for="footer-editor" class="q-mb-sm editor-label">
              {{ $t('components.accountPreferencesTab.labels.reportFooter') }}
            </label>

            <q-editor
              :toolbar="[
                [
                  'left',
                  'center',
                  'right',
                  'justify',
                  'bold',
                  'italic',
                  'underline',
                  'strike',
                  'undo',
                  'redo',
                  'Example',
                ],
                ['hr', 'link'],
              ]"
              id="footer-editor"
              v-model.trim="localAccount.reportFooter"
              min-height="5rem"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import useValidate from '@vuelidate/core';
import i18n from '@/helpers/i18n';

import { updateAccount } from '@/api/account';
import AppImageSelector from '@/components/AppImageSelector.vue';
import { formulas } from '@/api/misc';

export default {
  components: {
    AppImageSelector,
  },
  props: {
    account: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      v$: useValidate(),
      formulas: [],
      isEdit: false,
      localAccount: _.cloneDeep(this.account), // TODO: leave just 3 nodes: user, reportHeader and formula to avoid sending unnecessary data
    };
  },
  validations: {
    localAccount: {
      user: {
        locale: {},
        measurement: {},
      },
      reportHeader: {},
      reportFooter: {},
      formula: { required: true },
    },
  },
  computed: {
    buttons() {
      return [
        {
          isAllowed: this.$isAllowed('doctor/account/update'),
          color: this.isEdit ? 'secondary' : 'primary',
          label: this.isEdit
            ? this.$t('components.accountPreferencesTab.saveButtonTitle')
            : this.$t('components.accountPreferencesTab.editButtonTitle'),
          isOutline: !this.isEdit,
          onClick: this.isEdit ? this.save : this.edit,
        },
      ];
    },
  },
  async created() {
    this.updateTitle();

    this.formulas = await formulas();
  },
  watch: {},
  methods: {
    updateTitle() {
      this.$emit(
        'update:title',
        this.$t('components.accountPreferencesTab.title')
      );
    },
    edit() {
      this.isEdit = true;
    },
    async save() {
      this.v$.$touch();

      if (!this.v$.$invalid) {
        try {
          const account = await updateAccount(this.localAccount);

          this.localAccount.logo = account.logo;

          if (this.localAccount.user.locale !== i18n.locale) {
            i18n.global.locale = this.localAccount.user.locale;
            i18n.locale = this.localAccount.user.locale;
            this.$router.push(
              this.$getLocalizedPath({
                ...this.$route,
                params: {
                  ...this.$route.params,
                  locale: this.localAccount.user.locale,
                },
              })
            );
          }

          this.$emit('update:account', {
            ...this.account,
            ...account,
          });
          this.updateTitle();

          this.isEdit = false;
        } catch (error) {
          console.log(error);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.editor-label {
  font-size: 12px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.65);
}

.editor-result {
  white-space: pre-line;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.24);
  min-height: 28px;
}
</style>
