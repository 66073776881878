<template>
  <q-dialog v-model="isVisible">
    <q-card style="width: 400px; max-width: 80vw">
      <q-card-section
        class="row items-center justify-between bg-primary text-white"
      >
        <span class="text-h6">
          {{ $t('components.checkoutModal.title') }}
        </span>

        <q-btn
          :disable="isLoading"
          icon="close"
          flat
          round
          dense
          v-close-popup
        />
      </q-card-section>

      <q-separator />

      <q-card-section v-if="error" class="q-mt-md q-pt-none">
        <q-banner class="text-white bg-red" rounded>
          {{ error.message }}
        </q-banner>
      </q-card-section>

      <q-card-section class="q-pt-none q-mt-sm">
        <div v-if="isCardLoading" class="text-center q-pa-xl">
          <q-spinner color="primary" size="3em" />
        </div>

        <div ref="payment-form"></div>
      </q-card-section>

      <q-card-section v-if="!isCardLoading" class="q-py-none">
        <img src="../assets/stripe.svg" alt="" style="width: 150px" />
      </q-card-section>

      <q-card-actions v-if="!error" align="right">
        <q-btn :disable="isLoading" flat @click="close">
          {{ $t('components.checkoutModal.cancel') }}
        </q-btn>

        <q-btn
          :loading="isLoading"
          :label="$t('components.checkoutModal.checkout')"
          color="primary"
          flat
          @click="checkout"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import { prepare } from '@/api/purchases';

export default {
  data() {
    return {
      isVisible: false,
      isLoading: false,
      error: null,
      pack: null,
      stripe: null,
      elements: null,
      isCardLoading: false,
    };
  },
  methods: {
    open(pack) {
      this.error = null;
      this.isLoading = false;
      this.pack = pack;

      this.isVisible = true;

      this.loadStripe();
    },
    close() {
      this.isVisible = false;
    },
    loadStripe() {
      this.isCardLoading = true;

      const stripeScript = document.createElement('script');

      stripeScript.async = true;
      stripeScript.src = 'https://js.stripe.com/v3/';
      stripeScript.onload = () => {
        this.prepare();
      };

      document.head.appendChild(stripeScript);
    },
    async prepare() {
      try {
        const clientSecret = await prepare(this.pack.id);

        this.stripe = window.Stripe(process.env.VUE_APP_STRIPE_ID);

        this.elements = this.stripe.elements({
          clientSecret,
        });

        const paymentForm = this.elements.create('payment');
        paymentForm.mount(this.$refs['payment-form']);
      } catch (error) {
        this.error = error;
      } finally {
        this.isCardLoading = false;
      }
    },
    async checkout() {
      try {
        this.error = null;
        this.isLoading = true;

        const { error } = await this.stripe.confirmPayment({
          elements: this.elements,
          confirmParams: {
            return_url: `${window.location.origin}/checkout-confirmation`,
          },
        });

        if (error) {
          this.error = error;
        }

        this.isLoading = false;
      } catch (error) {
        this.error = error;
      }
    },
  },
};
</script>
