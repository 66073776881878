import axios from '@/helpers/axios';

export function getPacks() {
  return axios.post('/doctor/purchases/packs', {}).then(({ data }) => {
    return data.results;
  });
}

export function prepare(packId) {
  return axios
    .post('/doctor/purchases/prepare', {
      packId,
    })
    .then(({ data }) => {
      return data.intent.clientSecret;
    });
}

export function complete(paymentIntent) {
  return axios
    .post('/doctor/purchases/complete', {
      paymentIntent,
    })
    .then(({ data }) => {
      return data;
    });
}
