import Dialog from 'quasar/src/plugins/dialog/Dialog.js';import Loading from 'quasar/src/plugins/loading/Loading.js';import Notify from 'quasar/src/plugins/notify/Notify.js';;
import '@quasar/extras/roboto-font/roboto-font.css';
import '@quasar/extras/material-icons/material-icons.css';
import '@quasar/extras/fontawesome-v5/fontawesome-v5.css';
import '@quasar/extras/line-awesome/line-awesome.css';

import './styles/quasar.scss';

// To be used on app.use(Quasar, { ... })
export default {
  config: {},
  plugins: {
    Dialog,
    Loading,
    Notify,
  },
};
