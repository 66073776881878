<template>
  <div class="row q-col-gutter-md">
    <div class="col-12 col-md-6">
      <div class="text-h6 q-mb-md">
        {{ $t('components.accountBillingTab.sections.creditsHistory') }}
      </div>

      <account-credits-history
        ref="account-credits-history"
        :user-id="account.userId"
        class="col-12 col-md-6"
      />
    </div>
    <div class="col-12 col-md-6">
      <div
        v-if="!account.canPurchaseOnline"
        class="row full-height flex-center text-center"
      >
        <div class="column">
          <h5 class="q-ma-md text-primary">
            {{ $t('components.accountBillingTab.contactUs') }}
          </h5>
          <div class="col">
            <contact-us-btn />
          </div>
        </div>
      </div>

      <div v-if="account.canPurchaseOnline" class="column full-height no-wrap">
        <div class="text-h6 q-mb-md">
          {{ $t('components.accountBillingTab.sections.packs') }}
        </div>

        <div
          v-if="!account.companyName || !account.vatNumber"
          class="text-subtitle1 text-negative"
        >
          {{ $t('components.accountBillingTab.fillInfo') }}
        </div>

        <div
          v-if="account.companyName && account.vatNumber"
          class="column full-width full-height"
        >
          <q-card
            v-for="pack of packs"
            :key="pack.id"
            class="full-width q-mb-md"
            flat
            bordered
          >
            <q-card-section class="row full-width items-center">
              <div>
                <div class="text-subtitle1">
                  {{ pack.title }} - {{ pack.credits }}
                  {{ $t('components.accountBillingTab.creditsCount') }}
                </div>
              </div>

              <div class="q-ml-auto">
                <div class="text-subtitle2">
                  {{ $t('components.accountBillingTab.net') }}
                </div>

                <div class="text-subtitle2">
                  {{ $t('components.accountBillingTab.tax') }}
                </div>

                <div class="text-subtitle2 text-black">
                  {{ $t('components.accountBillingTab.total') }}
                </div>
              </div>

              <div class="q-ml-md text-right">
                <div class="text-subtitle2">
                  {{
                    new Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: pack.currency,
                    }).format(pack.amount)
                  }}
                </div>

                <div class="text-subtitle2">
                  {{
                    new Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: pack.currency,
                    }).format(pack.taxAmount)
                  }}
                </div>

                <div class="text-subtitle2 text-black">
                  {{
                    new Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: pack.currency,
                    }).format(+pack.amount + +pack.taxAmount)
                  }}
                </div>
              </div>

              <q-btn color="primary" class="q-ml-xl" @click="checkout(pack)">
                {{ $t('components.accountBillingTab.buy') }}
              </q-btn>
            </q-card-section>
          </q-card>

          <img
            src="../assets/stripe.svg"
            alt=""
            style="width: 150px; margin-top: auto"
          />
        </div>
      </div>
    </div>
  </div>

  <checkout-modal ref="checkout-modal" />
</template>

<script>
import { getPacks } from '@/api/purchases';
import AccountCreditsHistory from '@/components/AccountCreditsHistory';
import ContactUsBtn from '@/components/inputs/ContactUsBtn';
import CheckoutModal from '@/components/CheckoutModal';

export default {
  components: {
    ContactUsBtn,
    AccountCreditsHistory,
    CheckoutModal,
  },
  data() {
    return {
      packs: null,
    };
  },
  props: {
    account: {
      type: Object,
      requried: true,
    },
  },
  created() {
    this.updateTitle();
    this.updateButtons();
    this.getPacks();
  },
  methods: {
    updateTitle() {
      this.$emit(
        'update:title',
        this.$t('components.accountBillingTab.credits', {
          credits: this.$numeral(this.account.balance).format('0,0'),
        })
      );
    },
    updateButtons() {
      this.$emit('update:buttons', null);
    },
    async getPacks() {
      try {
        this.packs = await getPacks();
      } catch (error) {
        console.error(error);
      }
    },
    checkout(pack) {
      this.$refs['checkout-modal'].open(pack);
    },
  },
};
</script>
